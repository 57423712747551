import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Fragment, Suspense } from 'react';
import ReactDOM from 'react-dom';
import SplashScreen from 'components/SplashScreen'
import IncompatibleBrowserScreen from 'components/IncompatibleBrowserScreen'
import * as serviceWorker from './serviceWorker';

const App = React.lazy(() => import('./App'));

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Fragment>
        <IncompatibleBrowserScreen render={() => (
            <Suspense fallback={<SplashScreen />}>
                <App />
            </Suspense>
        )}/>
    </Fragment>, rootElement);
